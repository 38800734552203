import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'category',
        data: { pageTitle: 'afroCornerApp.category.home.title' },
        loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
      },
      {
        path: 'activity',
        data: { pageTitle: 'afroCornerApp.activity.home.title' },
        loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
      },
      {
        path: 'business',
        data: { pageTitle: 'afroCornerApp.business.home.title' },
        loadChildren: () => import('./business/business.module').then(m => m.BusinessModule),
      },
      {
        path: 'image',
        data: { pageTitle: 'afroCornerApp.image.home.title' },
        loadChildren: () => import('./image/image.module').then(m => m.ImageModule),
      },
      {
        path: 'location',
        data: { pageTitle: 'afroCornerApp.location.home.title' },
        loadChildren: () => import('./location/location.module').then(m => m.LocationModule),
      },
      {
        path: 'comment',
        data: { pageTitle: 'afroCornerApp.comment.home.title' },
        loadChildren: () => import('./comment/comment.module').then(m => m.CommentModule),
      },
      {
        path: 'review',
        data: { pageTitle: 'afroCornerApp.review.home.title' },
        loadChildren: () => import('./review/review.module').then(m => m.ReviewModule),
      },
      {
        path: 'reply',
        data: { pageTitle: 'afroCornerApp.reply.home.title' },
        loadChildren: () => import('./reply/reply.module').then(m => m.ReplyModule),
      },
      {
        path: 'thumbs',
        data: { pageTitle: 'afroCornerApp.thumbs.home.title' },
        loadChildren: () => import('./thumbs/thumbs.module').then(m => m.ThumbsModule),
      },
      {
        path: 'business-visit',
        data: { pageTitle: 'afroCornerApp.businessVisit.home.title' },
        loadChildren: () => import('./business-visit/business-visit.module').then(m => m.BusinessVisitModule),
      },
      {
        path: 'partner',
        data: { pageTitle: 'afroCornerApp.partner.home.title' },
        loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule),
      },
      {
        path: 'report-abuse',
        data: { pageTitle: 'afroCornerApp.reportAbuse.home.title' },
        loadChildren: () => import('./report-abuse/report-abuse.module').then(m => m.ReportAbuseModule),
      },
      {
        path: 'subscriber',
        data: { pageTitle: 'afroCornerApp.subscriber.home.title' },
        loadChildren: () => import('./subscriber/subscriber.module').then(m => m.SubscriberModule),
      },
      {
        path: 'contact',
        data: { pageTitle: 'afroCornerApp.contact.home.title' },
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'section',
        data: { pageTitle: 'afroCornerApp.section.home.title' },
        loadChildren: () => import('./section/section.module').then(m => m.SectionModule),
      },
      {
        path: 'shelf',
        data: { pageTitle: 'afroCornerApp.shelf.home.title' },
        loadChildren: () => import('./shelf/shelf.module').then(m => m.ShelfModule),
      },
      {
        path: 'article',
        data: { pageTitle: 'afroCornerApp.article.home.title' },
        loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
      },
      {
        path: 'document',
        data: { pageTitle: 'afroCornerApp.document.home.title' },
        loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
      },
      {
        path: 'verification',
        data: { pageTitle: 'afroCornerApp.verification.home.title' },
        loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule),
      },
      {
        path: 'echange',
        data: { pageTitle: 'afroCornerApp.echange.home.title' },
        loadChildren: () => import('./echange/echange.module').then(m => m.EchangeModule),
      },
      {
        path: 'voucher',
        data: { pageTitle: 'afroCornerApp.voucher.home.title' },
        loadChildren: () => import('./voucher/voucher.module').then(m => m.VoucherModule),
      },
      {
        path: 'user-remember-tag',
        data: { pageTitle: 'afroCornerApp.userRememberTag.home.title' },
        loadChildren: () => import('./user-remember-tag/user-remember-tag.module').then(m => m.UserRememberTagModule),
      },
      {
        path: 'business-tag',
        data: { pageTitle: 'afroCornerApp.businessTag.home.title' },
        loadChildren: () => import('./business-tag/business-tag.module').then(m => m.BusinessTagModule),
      },
      {
        path: 'formula',
        data: { pageTitle: 'afroCornerApp.formula.home.title' },
        loadChildren: () => import('./formula/formula.module').then(m => m.FormulaModule),
      },
      {
        path: 'abonnement',
        data: { pageTitle: 'afroCornerApp.abonnement.home.title' },
        loadChildren: () => import('./abonnement/abonnement.module').then(m => m.AbonnementModule),
      },
      {
        path: 'event',
        data: { pageTitle: 'afroCornerApp.event.home.title' },
        loadChildren: () => import('./event/event.module').then(m => m.EventModule),
      },
      /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
    ]),
  ],
})
export class EntityRoutingModule {}
