<nav data-cy="navbar" class="navbar navbar-light navbar-expand-md bg-light">
  <a class="navbar-brand logo" routerLink="/" (click)="collapseNavbar()">
    <span class="logo-img"></span>
    <span jhiTranslate="global.title" class="navbar-title">AfroCorner</span>
    <span class="navbar-version">{{ version }}</span>
  </a>
  <a
    class="navbar-toggler d-lg-none"
    href="javascript:void(0);"
    data-toggle="collapse"
    data-target="#navbarResponsive"
    aria-controls="navbarResponsive"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="toggleNavbar()"
  >
    <fa-icon icon="bars"></fa-icon>
  </a>
  <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed" [ngSwitch]="isAuthenticated()">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="/" (click)="collapseNavbar()">
          <span>
            <fa-icon icon="home"></fa-icon>
            <span jhiTranslate="global.menu.home">Home</span>
          </span>
        </a>
      </li>
      <!-- jhipster-needle-add-element-to-menu - JHipster will add new menu items here -->
      <li
        *ngSwitchCase="true"
        ngbDropdown
        class="nav-item dropdown pointer"
        display="dynamic"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu" data-cy="entity">
          <span>
            <fa-icon icon="th-list"></fa-icon>
            <span jhiTranslate="global.menu.entities.main">Entities</span>
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <li>
            <a
              class="dropdown-item"
              routerLink="category"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.category">Category</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="activity"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.activity">Activity</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="business"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.business">Business</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="image"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.image">Image</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="location"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.location">Location</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="comment"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.comment">Comment</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="review"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.review">Review</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="reply"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.reply">Reply</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="thumbs"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.thumbs">Thumbs</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="business-visit"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.businessVisit">Business Visit</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="partner"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.partner">Partner</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="report-abuse"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.reportAbuse">Report Abuse</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="subscriber"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.subscriber">Subscriber</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="contact"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.contact">Contact</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="section"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.section">Section</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="shelf"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.shelf">Shelf</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="article"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.article">Article</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="document"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.document">Document</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="verification"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.verification">Verification</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="echange"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.echange">Echange</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="voucher"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.voucher">Voucher</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="user-remember-tag"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.userRememberTag">User Remember Tag</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="business-tag"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.businessTag">Business Tag</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="formula"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.formula">Formula</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="abonnement"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.abonnement">Abonnement</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="event"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="collapseNavbar()"
            >
              <fa-icon icon="asterisk" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.entities.event">Event</span>
            </a>
          </li>
          <!-- jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here -->
        </ul>
      </li>
      <li
        *jhiHasAnyAuthority="'ROLE_ADMIN'"
        ngbDropdown
        class="nav-item dropdown pointer"
        display="dynamic"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="admin-menu" data-cy="adminMenu">
          <span>
            <fa-icon icon="users-cog"></fa-icon>
            <span jhiTranslate="global.menu.admin.main">Administration</span>
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="admin-menu">
          <li>
            <a class="dropdown-item" routerLink="admin/user-management" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="users" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.admin.userManagement">User management</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/metrics" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="tachometer-alt" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.admin.metrics">Metrics</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/health" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="heart" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.admin.health">Health</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/configuration" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="cogs" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.admin.configuration">Configuration</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/logs" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="tasks" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.admin.logs">Logs</span>
            </a>
          </li>
          <li *ngIf="openAPIEnabled">
            <a class="dropdown-item" routerLink="admin/docs" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="book" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.admin.apidocs">API</span>
            </a>
          </li>
          <!-- jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here -->
        </ul>
      </li>
      <li ngbDropdown class="nav-item dropdown pointer" display="dynamic" *ngIf="languages && languages.length > 1">
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
          <span>
            <fa-icon icon="flag"></fa-icon>
            <span jhiTranslate="global.menu.language">Language</span>
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
          <li *ngFor="let language of languages">
            <a
              class="dropdown-item"
              [jhiActiveMenu]="language"
              href="javascript:void(0);"
              (click)="changeLanguage(language); collapseNavbar()"
              >{{ language | findLanguageFromKey }}</a
            >
          </li>
        </ul>
      </li>
      <li
        ngbDropdown
        class="nav-item dropdown pointer"
        display="dynamic"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu" data-cy="accountMenu">
          <span *ngIf="!getImageUrl()">
            <fa-icon icon="user"></fa-icon>
            <span jhiTranslate="global.menu.account.main">Account</span>
          </span>
          <span *ngIf="getImageUrl()">
            <img [src]="getImageUrl()" class="profile-image rounded-circle" alt="Avatar" />
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
          <li *ngSwitchCase="true">
            <a class="dropdown-item" routerLink="account/settings" routerLinkActive="active" (click)="collapseNavbar()" data-cy="settings">
              <fa-icon icon="wrench" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.account.settings">Settings</span>
            </a>
          </li>
          <li *ngSwitchCase="true">
            <a
              class="dropdown-item"
              routerLink="account/password"
              routerLinkActive="active"
              (click)="collapseNavbar()"
              data-cy="passwordItem"
            >
              <fa-icon icon="lock" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.account.password">Password</span>
            </a>
          </li>
          <li *ngSwitchCase="true">
            <a class="dropdown-item" (click)="logout()" id="logout" data-cy="logout">
              <fa-icon icon="sign-out-alt" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.account.logout">Sign out</span>
            </a>
          </li>
          <li *ngSwitchCase="false">
            <a class="dropdown-item" (click)="login()" id="login" data-cy="login">
              <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.account.login">Sign in</span>
            </a>
          </li>
          <li *ngSwitchCase="false">
            <a class="dropdown-item" routerLink="account/register" routerLinkActive="active" (click)="collapseNavbar()" data-cy="register">
              <fa-icon icon="user-plus" [fixedWidth]="true"></fa-icon>
              <span jhiTranslate="global.menu.account.register">Register</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
